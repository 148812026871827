<template>
  <div class="sider-dashboard-section" :class="{ 'sider-dashboard-section__hidden': !isSiderOpened }">
    <v-list style="text-align: left !important" dense>
      <v-list-item-group v-model="filterIdProxy" @change="openFilter">
        <template v-for="(category, index) in Object.values(filtersDict).filter(type => type.items.length)">
          <v-subheader
            :key="index"
            class="text-truncate sider-animation"
            :class="{ 'px-6': isSiderOpened, 'px-4': !isSiderOpened }"
            >{{ category.name }}</v-subheader
          >
          <v-list-item
            v-for="(item, itemIndex) in category.items"
            :key="`${index}-${itemIndex}`"
            :value="item.id"
            class="sider-list-item"
          >
            <v-list-item-content>
              <v-list-item-title class="px-2">{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';

export default {
  name: 'SiderDashboardSection',
  props: {
    isSiderOpened: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    filterIdProxy: null,
  }),
  watch: {
    filterId: {
      handler(nV) {
        this.filterIdProxy = nV;
      },
      immediate: true,
    },
  },
  computed: {
    dashboardFilters: get('dashboard/filters'),
    filterId() {
      return this.$route.query.filter ? +this.$route.query.filter : null;
    },
    filtersDict() {
      return {
        predefined: {
          name: this.$i18n.t('dashboard.filter.predefined'),
          items: this.dashboardFilters?.predefined ?? [],
        },
        owner: {
          name: this.$i18n.t('dashboard.filter.owned'),
          items: this.dashboardFilters?.owner ?? [],
        },
        shared: {
          name: this.$i18n.t('dashboard.filter.shared'),
          items: this.dashboardFilters?.shared ?? [],
        },
      };
    },
  },
  methods: {
    openFilter(filterId) {
      this.$nextTick(() => (this.filterIdProxy = this.filterId));
      if (!filterId) return;
      const filterData = Object.values(this.dashboardFilters)
        .flat()
        .find(filter => filter.id === filterId);
      this.$router.push({
        name: 'dashboardLayer',
        params: {
          lid: filterData.layer_id,
        },
        query: {
          filter: filterData.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sider-dashboard-section {
  height: 100%;
  transition: opacity 0.2s ease allow-discrete;
}
.sider-dashboard-section__hidden {
  opacity: 0 !important;
  pointer-events: none !important;
}
</style>
